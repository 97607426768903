// extracted by mini-css-extract-plugin
export var aboutItem = "singlePortfolio-module--aboutItem--36f34";
export var aboutProject = "singlePortfolio-module--aboutProject--e31c7";
export var block = "singlePortfolio-module--block--36223";
export var category = "singlePortfolio-module--category--e17f6";
export var left = "singlePortfolio-module--left--61a54";
export var lists = "singlePortfolio-module--lists--4dd50";
export var mainInfo = "singlePortfolio-module--mainInfo--dbdf5";
export var mobileDevice = "singlePortfolio-module--mobileDevice--0c6c5";
export var mobileImg = "singlePortfolio-module--mobileImg--08de0";
export var mobileWrapp = "singlePortfolio-module--mobileWrapp--dfaaa";
export var modified = "singlePortfolio-module--modified--adda9";
export var onlyDesc = "singlePortfolio-module--onlyDesc--59706";
export var right = "singlePortfolio-module--right--ddf14";
export var scroll = "singlePortfolio-module--scroll--98d54";
export var singlePortfolio = "singlePortfolio-module--singlePortfolio--8c6f5";
export var siteLink = "singlePortfolio-module--siteLink--99fba";
export var siteUrl = "singlePortfolio-module--siteUrl--3ad58";
export var textInfo = "singlePortfolio-module--textInfo--f31da";
export var wrap = "singlePortfolio-module--wrap--f5e49";